<template>
  <div class="noVieweTask">
    <ScrollTable
      ref="ScrollTable"
      :key="refreshKey"
      style="flex: 1; margin-top: -8px; margin-right: 8px; overflow: hidden"
      is-global-query
      :columns="[]"
      :query="{
        readFlag: '02',
      }"
      :class-option="{
        step: 0.3,
        limitMoveNum: 1,
      }"
      :url="`/${$businessISG}/monitor/home/warn-page`"
      @clickRow="({ row }) => $refs.SubTaskInfoDialog.openDialog({ row })"
    >
      <div
        slot="scroll-data"
        slot-scope="{ formatDataSource }"
        style="display: flex; flex-direction: column"
      >
        <div
          v-for="(e, i) in formatDataSource"
          :key="i"
          class="scroll-row"
          :data-index="i"
          style="display: flex"
        >
          <div class="scroll-col" style="width: 40%">
            {{ e.createdTime ? e.createdTime : '-' }}
          </div>
          <div class="scroll-col" style="width: 10%">
            {{ e.typeText ? e.typeText : '-' }}
          </div>
          <div class="scroll-col" style="width: 10%">
            {{ e.readFlagText ? e.readFlagText : '-' }}
          </div>
          <div class="scroll-col" style="width: 40%">
            {{ e.remark ? e.remark : '-' }}
          </div>
        </div>
      </div>
    </ScrollTable>
    <div
      style="
        border: 1px solid;
        padding: 4px 10px;
        border-radius: 3px;
        cursor: pointer;
      "
      @click="
        $refs.EarlyNumberTableDialog.openDialog({
          dialog: {
            title: '预警列表',
          },
        })
      "
    >
      》更多
    </div>
    <EarlyNumberTableDialog
      ref="EarlyNumberTableDialog"
    ></EarlyNumberTableDialog>
    <SubTaskInfoDialog
      ref="SubTaskInfoDialog"
      @read="$refs.ScrollTable && $refs.ScrollTable.refresh()"
    ></SubTaskInfoDialog>
  </div>
</template>

<script>
import EarlyNumberTableDialog from './EarlyNumberTableDialog.vue';
import SubTaskInfoDialog from './SubTaskInfoDialog.vue';
export default {
  //组件注册
  components: {
    ScrollTable: () => import('@/views/screen/components/ScrollTable'),
    EarlyNumberTableDialog,
    SubTaskInfoDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
    this.$bus.$on('read-warn', () => {
      this.$refs.ScrollTable && this.$refs.ScrollTable.refresh();
    });
  },
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.noVieweTask {
  position: absolute;
  top: -40px;
  left: 0;
  display: flex;
  width: 100%;
  height: 36px;
  .scroll-col {
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #e9faff;
    word-break: break-all;
    text-align: center;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
