var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"noVieweTask"},[_c('ScrollTable',{key:_vm.refreshKey,ref:"ScrollTable",staticStyle:{"flex":"1","margin-top":"-8px","margin-right":"8px","overflow":"hidden"},attrs:{"is-global-query":"","columns":[],"query":{
      readFlag: '02',
    },"class-option":{
      step: 0.3,
      limitMoveNum: 1,
    },"url":("/" + _vm.$businessISG + "/monitor/home/warn-page")},on:{"clickRow":function (ref) {
        var row = ref.row;

        return _vm.$refs.SubTaskInfoDialog.openDialog({ row: row });
}},scopedSlots:_vm._u([{key:"scroll-data",fn:function(ref){
    var formatDataSource = ref.formatDataSource;
return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((formatDataSource),function(e,i){return _c('div',{key:i,staticClass:"scroll-row",staticStyle:{"display":"flex"},attrs:{"data-index":i}},[_c('div',{staticClass:"scroll-col",staticStyle:{"width":"40%"}},[_vm._v(" "+_vm._s(e.createdTime ? e.createdTime : '-')+" ")]),_c('div',{staticClass:"scroll-col",staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(e.typeText ? e.typeText : '-')+" ")]),_c('div',{staticClass:"scroll-col",staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(e.readFlagText ? e.readFlagText : '-')+" ")]),_c('div',{staticClass:"scroll-col",staticStyle:{"width":"40%"}},[_vm._v(" "+_vm._s(e.remark ? e.remark : '-')+" ")])])}),0)}}])}),_c('div',{staticStyle:{"border":"1px solid","padding":"4px 10px","border-radius":"3px","cursor":"pointer"},on:{"click":function($event){return _vm.$refs.EarlyNumberTableDialog.openDialog({
        dialog: {
          title: '预警列表',
        },
      })}}},[_vm._v(" 》更多 ")]),_c('EarlyNumberTableDialog',{ref:"EarlyNumberTableDialog"}),_c('SubTaskInfoDialog',{ref:"SubTaskInfoDialog",on:{"read":function($event){_vm.$refs.ScrollTable && _vm.$refs.ScrollTable.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }